<template>
  <IAMModal
    ref="documentModal"
    size="xl"
    title="Document genereren"
  >
    <div class="row">
      <div class="col-sm-12">
        <FormulateInput
          v-model="name"
          name="name"
          label="Documentnaam"
          placeholder="Documentnaam"
          :input-class="['tw-text-sm tw-h-8 tw-mt-0.5']"
          outer-class="tw-w-1/4 tw-mb-4"
        />
      </div>
      <div class="col-sm-6">
        <template v-if="showLanguages">
          <label
            v-if="templateContainsLanguage('nl')"
            class="tw-inline-block tw-my-2 tw-mx-1"
          >
            <input
              v-model="language"
              type="radio"
              value="nl"
            >
             NL
          </label>
          <label
            v-if="templateContainsLanguage('fr')"
            class="tw-inline-block tw-my-2 tw-mx-1"
          >
            <input
              v-model="language"
              type="radio"
              value="fr"
            >
             FR
          </label>
          <label
            v-if="templateContainsLanguage('en')"
            class="tw-inline-block tw-my-2 tw-mx-1"
          >
            <input
              v-model="language"
              type="radio"
              value="en"
            >
             EN
          </label>
        </template>
        <div
          v-if="contactLangNotInTemplate"
          class="tw-text-red-700"
        >
          Dit contact heeft als taal {{ getFullLanguage(selectedContact.language) }}, maar de template is niet beschikbaar in deze taal. Er zal een Nederlands document gegenereerd worden.
        </div>
      </div>
    </div>
    <div class="tabs-container">
      <ul class="nav nav-tabs">
        <li id="file_type_tab" class="active document-modal-tab">
          <a
            ref="document_type_tab"
            aria-expanded="true"
            data-toggle="tab"
            href="#tab-file-types"
          >
            Document
          </a>
        </li>
        <li class="document-modal-tab">
          <a
            v-if="selectedTemplate && selectedTemplate.requires_recipient"
            aria-expanded="false"
            data-toggle="tab"
            href="#tab-contacts"
          >
            Geadresseerde
          </a>
        </li>
        <li class="document-modal-tab">
          <a
            v-if="selectedTemplate && selectedTemplate.template_fields.length"
            aria-expanded="false"
            data-toggle="tab"
            href="#tab-fields"
          >
            Velden
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <FormulateInput
          v-model="query"
          type="autocomplete"
          auto-complete-type="templates"
          name="template"
          placeholder="Zoeken op document "
          :input-class="['tw-text-sm tw-h-8 tw-mt-0.5']"
          outer-class="tw-w-1/4 tw-my-4"
          @input="setTemplate"
        />
        <div
          id="tab-file-types"
          style="margin-top: 5px"
          class="tab-pane active document-modal-tab"
        >
          <div class="row">
            <div class="col-sm-4">
              <IAMTree
                v-if="templates.length"
                :nodes="templates"
                :selected-node="selectedCategory"
                identifier="path"
                @selected="selectCategory"
              />
              <span v-else class="tw-ml-[5px]">
                <i
                  class="fa fa-spinner fa-spin tw-mr-1"
                />
                Documenten laden ...
              </span>
            </div>
            <div class="col-sm-8">
              <h4>{{ (selectedCategory && selectedCategory.path) || 'Selecteer een categorie' }}</h4>

              <div class="tw-my-2 tw-overflow-auto">
                <table v-show="visibleTemplates.length" class="tw-w-full">
                  <thead>
                    <th>Title</th>
                    <th>Talen</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="template in visibleTemplates"
                      :key="`template_${template.id}`"
                      :title="template.name"
                      :class="[
                        'tw-font-semibold tw-cursor-pointer tw-border-b last:tw-border-none hover:tw-underline',
                        { 'tw-bg-primary tw-text-white': selectedTemplate && selectedTemplate.id === template.id }
                      ]"
                      @click="setSelectedTemplate(template)"
                    >
                      <td>{{ template.name }}</td>
                      <td class="tw-flex tw-flex-wrap tw-gap-x-1">
                        <span
                          v-for="language in template.languages"
                          :key="`template_${template.id}_lang_${language}`"
                          class="tw-uppercase"
                        >
                          {{ language }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <em v-if="visibleTemplates.length == 0 && selectedCategory">
                Er zijn geen templates in deze map
              </em>
              <div v-if="selectedTemplate && selectedTemplate.description">
                <h4>Beschrijving</h4>
                <p>{{ selectedTemplate.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div id="tab-contacts" class="tab-pane document-modal-tab">
          <div class="row">
            <div class="col-sm-6">
              <div class="row">
                <div v-if="contacts.owners" class="col-md-12">
                  <h4>Eigenaars</h4>
                </div>
                <div
                  v-for="contact in contacts.owners"
                  :key="`owners_${contact.id}`"
                  class="col-sm-3"
                >
                  <ContactButton
                    :value="contact"
                    @select="selectContact"
                  />
                </div>
                <div v-if="contacts.buyers" class="col-md-12">
                  <h4>Kopers</h4>
                </div>
                <div
                  v-for="contact in contacts.buyers"
                  :key="`buyers_${contact.id}`"
                  class="col-sm-3"
                >
                  <ContactButton
                    :value="contact"
                    @select="selectContact"
                  />
                </div>
                <div
                  v-if="!(contacts.buyers && contacts.buyers.length)"
                  class="col-sm-12"
                >
                  <p>
                    <em>Er zijn geen kopers gekoppeld aan dit pand</em>
                  </p>
                </div>
                <div v-if="contacts.renters" class="col-md-12">
                  <h4>Huurders</h4>
                </div>
                <div
                  v-for="contact in contacts.renters"
                  :key="`renters_${contact.id}`"
                  class="col-sm-3"
                >
                  <ContactButton
                    :value="contact"
                    @select="selectContact"
                  />
                </div>
                <div
                  v-if="!(contacts.renters && contacts.renters.length)"
                  class="col-sm-12"
                >
                  <p>
                    <em>Er zijn geen huurders gekoppeld aan dit pand</em>
                  </p>
                </div>
              </div>

              <div class="row">
                <div
                  v-if="contacts.notary_of_owners"
                  class="col-md-3"
                >
                  <h4>Notaris eigenaars</h4>
                  <ContactButton
                    :value="contacts.notary_of_owners"
                    @select="selectContact"
                  />
                </div>
                <div
                  v-if="contacts.notary_of_buyers"
                  class="col-md-3"
                >
                  <h4>Notaris kopers</h4>
                  <ContactButton
                    :value="contacts.notary_of_buyers"
                    @select="selectContact"
                  />
                </div>
                <div
                  v-if="contacts.syndic"
                  class="col-md-3"
                >
                  <h4>Syndicus</h4>
                  <ContactButton
                    :value="contacts.syndic"
                    @select="selectContact"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Geselecteerd contact</h4>
                  <ContactPicker
                    v-model="selectedContact"
                    :selectionRenderComponent="ContactButton"
                    placeholder="Kies een contact aan de linkerzijde of zoek hier in de contactendatabase"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedTemplate && selectedTemplate.template_fields.length"
          class="tab-pane document-modal-tab"
          id="tab-fields"
        >
          <div
            id="fields_row"
            class="row"
            style="margin-top: 5px;"
          >
            <form class="form-horizontal">
              <div
                v-for="field in selectedTemplate.template_fields"
                :key="`field_${field.id}`"
                class="form-group"
              >
                <label
                  :for="field.field_name"
                  class="col-lg-2 control-label"
                >
                  {{ field.display_name }}
                </label>
                <div class="controls col-lg-8">
                  <input
                    v-model="templateFields[field.field_name]"
                    :id="field.field_name"
                    :name="field.field_name"
                    type="text"
                    class="document_template_field form-control textinput"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer">
      <button
        id="btn-generate_document"
        type="button"
        :disabled="disableGenerate"
        data-dismiss="modal"
        :class="['btn', disableGenerate ? 'btn-default' : 'btn-primary']"
        @click="saveDocumentHandler"
      >
        Document genereren
      </button>
      <button
        type="button"
        data-dismiss="modal"
        class="btn btn-link"
      >
        Annuleren
      </button>
    </div>
  </IAMModal>
</template>

<script>
import $ from 'jquery'

import IAMModal from '@/components/iam/Modal'
import IAMTree from '@/components/iam/Tree'
import ContactPicker from '@/components/contacts/ContactPicker'
import ContactButton from '@/components/properties/ContactButton'

import { errorModal, startLoadingModal, successModal } from '@/modalMessages'
import { getCategory, getTemplate, getTemplates, createDocument, pollGutenborg } from '@/services/gutenborgService'
import { getDocumentMergeData, finishDocument } from '@/services/documents'
import { getPropertyAddressableContacts } from '@/services/properties'
import { getProjectAddressableContacts } from '@/services/projects'
import { poll } from '@/utils/helpers'

export default {
  name: 'CreateEntityFileModal',
  components: {
    IAMModal,
    IAMTree,
    ContactPicker,
    ContactButton
  },
  props: {
    property: {
      type: Object,
      default () {
        return null
      }
    },
    project: {
      type: Object,
      default () {
        return null
      }
    },
    folder: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      ContactButton, // To provide the component to ContactPicker as a selectionRenderComponent
      selectedTemplate: '',
      selectedContact: '',
      templateCache: [],
      templates: [],
      templateFields: {},
      contacts: [],
      name: '',
      query: '',
      selectedCategory: {},
      visibleTemplates: [],
      language: 'nl'
    }
  },
  computed: {
    disableGenerate () {
      if (this.selectedTemplate) {
        if (this.selectedTemplate.requires_recipient) {
          return !(this.selectedContact && this.name)
        } else {
          return !(this.name)
        }
      } else {
        return !(this.selectedContact && this.name)
      }
    },
    showLanguages () {
      return this.selectedTemplate?.languages?.length > 1
    },
    contactLangNotInTemplate () {
      if (this.selectedTemplate?.requires_recipient && this.selectedContact) {
        return !this.templateContainsLanguage(this.selectedContact.language)
      }
      return false
    },
    entity () {
      if (this.property) {
        return {
          obj: this.property,
          idVariable: 'property_id',
          typeStr: 'properties',
          templateType: this.PROPERTY_TEMPLATE_TYPE,
          getContacts: getPropertyAddressableContacts,
          folder: `${this.property.id}`
        }
      }
      return {
        obj: this.project,
        idVariable: 'project_id',
        typeStr: 'projects',
        templateType: this.PROJECT_TEMPLATE_TYPE,
        getContacts: getProjectAddressableContacts,
        folder: `project_${this.project.id}`
      }
    }
  },
  constants: {
    PROPERTY_TEMPLATE_TYPE: 2,
    PROJECT_TEMPLATE_TYPE: 7
  },
  methods: {
    async show () {
      this.$refs.documentModal.show()
      const response = await Promise.all([this.fetchFileTemplates(), this.fetchEntityContacts()])
      return response
    },
    async fetchEntityContacts () {
      try {
        const response = await this.entity.getContacts(this.entity.obj.id)
        this.contacts = response.data
        return response
      } catch {
        errorModal('Er ging iets mis bij het inladen van de contacten voor dit pand.')
      }
    },
    async fetchFileTemplates () {
      try {
        const templateType = this.entity.templateType // EntityFile type
        const response = await getTemplates(templateType)
        this.templateCache = response.data.children
        this.templates = this.templateCache
      } catch (error) {
        console.error(`Error loading document templates: ${error}`)
        errorModal('Templates konden niet geladen worden.')
      }
    },
    selectCategory (category) {
      this.selectedCategory = category
      this.visibleTemplates = category.templates
      this.selectedTemplate = null
      this.query = ''
    },
    selectContact (contact) {
      this.selectedContact = contact
      if (!this.selectedTemplate?.languages?.includes(contact.language)) {
        this.language = 'nl'
      } else {
        this.language = contact.language
      }
    },
    async generateMergeData () {
      const payload = {
        template_fields: this.templateFields,
        recipient_id: this.selectedContact?.id
      }
      payload.entity_id = this.entity.obj.id
      return await getDocumentMergeData(this.entity.typeStr, this.entity.obj.id, payload)
    },
    generateDocumentPayload (mergeData) {
      return {
        merge_data: mergeData,
        template_id: this.selectedTemplate.id,
        config: {
          type: 'source',
          destination: 'drive',
          name: this.name,
          folder: this.entity.folder,
          language: this.language
        }
      }
    },
    async createEntityFile (documentUrl) {
      const payload = {
        recipient_id: this.selectedContact?.id,
        filename: this.name,
        url: documentUrl
      }
      payload[this.entity.idVariable] = this.entity.obj.id
      // folder_id: unset is used to only filter files that are not associated with a folder.
      // To create files at the Bestanden level (i.e. root), just don't pass any folder id
      if (this.folder?.id !== 'unset') payload.folder_id = this.folder.id
      await finishDocument(this.entity.typeStr, payload)
    },
    async saveDocumentHandler () {
      startLoadingModal('Je document wordt gegenereerd')
      // Generate payload for document
      try {
        const mergeData = await this.generateMergeData()
        const documentPayload = this.generateDocumentPayload(mergeData.data)
        const document = await createDocument(documentPayload)
        const pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
        await this.createEntityFile(pollResult?.url)
        this.$emit('fetchFiles')
        successModal('Je document is aangemaakt')
        const win = window.open(pollResult?.url, '_blank')
        if (win) win.focus()
        else errorModal('Sta pop-ups toe om het document te zien.')
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets mis bij het genereren van jouw document, gelieve nogmaals te proberen')
      }
    },
    setSelectedTemplate (template) {
      if (this.selectedTemplate?.id === template.id) this.selectedTemplate = null
      else {
        this.selectedTemplate = template
        if (!template.requires_recipient) this.selectedContact = null
      }
    },
    clearAllFields () {
      this.selectedTemplate = ''
      this.selectedContact = ''
      this.query = ''
      this.name = ''
      this.selectedCategory = null
      $('.document-modal-tab').removeClass('active')
      $('#tab-file-types').addClass('active')
      $('#file_type_tab').addClass('active')
      this.visibleTemplates = []
    },
    fieldRef (field) {
      return `${field.id}-${field.field_name}`
    },
    templateContainsLanguage (language) {
      return this.selectedTemplate?.languages?.includes(language)
    },
    getFullLanguage (language) {
      return { nl: 'Nederlands', fr: 'Frans', en: 'Engels' }[language]
    },
    async setTemplate (template) {
      if (!template) return
      const categoryResponse = await getCategory(template.category)
      const templateResponse = await getTemplate(template.id)
      this.selectedCategory = categoryResponse.data
      this.visibleTemplates = categoryResponse.data.templates
      this.selectedTemplate = templateResponse.data
      return [categoryResponse, templateResponse]
    }
  },
  watch: {
    selectedTemplate (value) {
      if (value) {
        this.name = value.file_name_suggestion + '_' + this.property.id
      }
      if (value?.template_fields?.length) {
        value.template_fields.forEach(field => {
          this.templateFields[field.field_name] = ''
        })
      } else {
        this.templateFields = {}
      }
    },
    selectedContact (value) {
      if (!value || !this.templateContainsLanguage(value.language)) {
        this.language = 'nl'
      } else {
        this.language = value.language
      }
    },
    query (value) {
      if (value.length >= 3) {
        this.templates = this.templateCache.filter(function (template) {
          return template.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        })
      } else {
        this.templates = this.templateCache
      }
    }
  }
}
</script>

<style scoped>
td, th {
  @apply tw-px-2 tw-py-1;
}
</style>
