<template>
  <div>
    <DataGrid
      :actions="actions"
      :columns="columns"
      :data="packages"
      @f2f="startLiveSessie"
      @status="showStatus"
      id="dgPackageTable"
      ref="packageTable"
    />
    <Modal ref="statusModal" title="Details ondertekensessie">
      <div v-if="signaturePackage !== null">
        <dl class="dl-horizontal">
          <dt>Package ID</dt>
          <dd>{{ signaturePackage.id }}</dd>
          <dt>Status</dt>
          <dd>
            <label class="label label-success" v-if="signaturePackage.status==='Finished'">Ondertekend</label>
            <label class="label label-info" v-if="signaturePackage.status==='Pending'">In afwachting</label>
            <label class="label label-default" v-if="signaturePackage.status==='Revoked'">Ingetrokken</label>
            <label class="label label-warning" v-if="signaturePackage.status==='Expired'">Vervallen</label>
            <label class="label label-warning" v-if="signaturePackage.status==='Rejected'">Geweigerd</label>
            <label class="label label-warning" v-if="signaturePackage.status==='Failed'">Mislukt</label>
          </dd>
          <dt>Acties</dt>
          <dd>
            <div v-if="signaturePackage.status === 'Pending'">
              <p>Dit pakket is nog niet ondertekend en kan nog worden ingetrokken. Als een pakket ingetrokken is
                kan dit niet langer ondertekend worden.
                <a @click="revoke(signaturePackage.id)">Klik hier om de ondertekensessie in te trekken</a>.
              </p>

            </div>
            <div v-if="signaturePackage.status === 'Finished'">
              <p>Dit pakket is ondertekend en kan nog worden gedownload. De volledige inhoud van het pakket zal
                gedownload worden.
                <a :href="packageDownloadUrl(signaturePackage.id)">Klik hier om het pakket te downloaden</a>.
              </p>
            </div>
          </dd>
        </dl>

        <h4>Ondertekenaars</h4>
        <div v-if="signaturePackage.status === 'Pending'">
          <p>
            Nog niet alle ondertekenaars hebben het pakket ondertekend.
            <a @click="sendReminder(signaturePackage.id)">klik hier om een herinnering te versturen</a>.
          </p>
        </div>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Ondertekenaar</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr :key="stakeholder.email" v-for="stakeholder in signaturePackage.stakeholders">
            <td>{{ stakeholder.email }}</td>
            <td>
              <span :key="role.type" v-for="role in stakeholder.roles">
                <span class="label label-warning" v-if="role.type === 'signer' && !role.completed">Ondertekenaar</span>
                <span class="label label-primary" v-if="role.type === 'signer' && role.completed">Ondertekenaar</span>
                <span class="label label-info" v-if="role.type === 'receiver'">Ontvanger</span>
              </span>
            </td>
            <td class="text-right">
              <span :key="role.type" v-for="role in stakeholder.roles">
                <a :href="role.actionUrl" class="btn btn-xs btn-default" target="_blank"
                   v-if="role.type === 'signer' && !role.completed && signaturePackage.status === 'Pending'">Start sessie</a>
                <span
                  v-if="role.type === 'signer' && role.completed">Ondertekend op {{ role.completed|datetime }}</span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import swal from 'sweetalert2'
import $ from 'jquery'
import EventBus from '@/components/iam/bus'
import DataGrid from '../iam/DataGrid'
import Modal from '../iam/Modal'
import { startLoadingModal, stopLoadingModal, successModal, warningModal, errorModal } from '@/modalMessages'
import { getStakeholders, refreshStatus } from '@/services/esignatures'

Vue.component('package-status', {
  template: '<span class="label" :class="style">{{ status }}</span>',
  props: ['value', 'object'],
  computed: {
    status () {
      if (this.value === 1) {
        return 'In afwachting'
      }
      if (this.value === 2) {
        return 'Ondertekend'
      }
      if (this.value === 9) {
        return 'Ingetrokken'
      }
      return ''
    },
    style () {
      if (this.value === 1) {
        return 'label-info'
      }
      if (this.value === 2) {
        return 'label-success'
      }
      if (this.value === 9) {
        return 'label-default'
      }
      return 'label-default'
    }
  }
})

export default {
  name: 'SignaturePackageTable',
  components: { Modal, DataGrid },
  props: ['property'],
  mounted () {
    EventBus.$on('CreatedSigningPackage', () => {
      this.$refs.packageTable.reload()
    })
  },
  data () {
    return {
      signaturePackage: null,
      columns: [
        { title: 'Package', key: 'name' },
        { title: 'Status', key: 'status', renderComponent: 'package-status' }
      ],
      actions: [
        {
          key: 'f2f',
          type: 'default',
          icon: 'pencil',
          title: 'Live Sessie Starten',
          label: 'Live Sessie'
        },
        {
          key: 'status',
          type: 'default',
          icon: 'search',
          title: 'Toon status',
          label: 'Status'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertySignaturePackages']),

    packages () {
      if (this.property) {
        return this.getPropertySignaturePackages(this.property.id)
      }
      return []
    }
  },
  async created () {
    await this.loadPropertySignaturePackages(this.property.id)
  },
  methods: {
    ...mapActions('properties', ['loadPropertySignaturePackages']),

    packageDownloadUrl (packageId) {
      return `/api/esignatures/packages/${packageId}/download`
    },
    revoke (packageId) {
      $.ajax({
        type: 'POST',
        url: '/api/esignatures/packages/' + packageId + '/revoke'
      }).done(() => {
        this.$refs.statusModal.hide()
        successModal('Het pakket is succesvol ingetrokken')
        this.loadPropertySignaturePackages(this.property.id)
      })
    },
    sendReminder (packageId) {
      $.ajax({
        type: 'POST',
        url: `/api/esignatures/packages/${packageId}/reminder`
      }).done(() => {
        successModal('Herinneringen zijn verstuurd naar alle ondertekenaars die nog niet ondertekend hebben')
      })
    },
    async showStatus (packageObj) {
      try {
        this.signaturePackage = null
        startLoadingModal('Status van het ondertekenpakket wordt opgehaald bij Connective...')
        const refreshStatusResponse = await refreshStatus(packageObj.id)
        const stakeholdersResponse = await getStakeholders(packageObj.id)
        this.loadPropertySignaturePackages(this.property.id)

        const stakeholders = stakeholdersResponse?.data?.stakeholders

        this.signaturePackage = {
          id: packageObj.id,
          status: refreshStatusResponse?.data?.data?.status,
          stakeholders: {}
        }

        if (!stakeholders) throw new Error('No stakeholders data found')

        stakeholders.forEach(stakeholder => {
          const stakeholderId = stakeholder.Id
          const email = stakeholder.EmailAddress
          this.signaturePackage.stakeholders[stakeholderId] = {
            email,
            roles: []
          }
          stakeholder.Actors.forEach(actor => {
            const role = actor?.Type
            let completed = ''
            let actionUrl = ''
            if (role === 'signer') {
              completed = actor?.Result?.CompletedDate
              actionUrl = actor?.ActionUrls[0]?.Url
            }
            this.signaturePackage.stakeholders[stakeholderId].roles.push({
              type: role,
              completed: completed,
              actionUrl: actionUrl
            })
          })
        })
        this.$refs.statusModal.show()
        stopLoadingModal()
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het opvragen van de status van het ondertekenpakket.')
      }
    },
    startLiveSessie (packageObj) {
      startLoadingModal('Face 2 Face Signing URL wordt aangemaakt...')

      $.ajax({
        type: 'GET',
        url: '/api/esignatures/packages/' + packageObj.id + '/f2furl'
      }).done((data) => {
        if (data.error) return warningModal('Het was niet mogelijk om een nieuwe Face 2 Face Signing URL op te halen. Vermoedelijk is dit pakket al volledig ondertekend.')

        swal({
          title: 'Een nieuwe Face 2 Face URL is aangemaakt',
          text: 'Een nieuwe Face 2 Face URL is aangemaakt. Klik op de knop hieronder om de onderteken sessie te starten. Opgelet: een beveiligde URL kan slechts éénmaal geopend worden.',
          type: 'success',
          showCancelButton: true,
          confirmButtonColor: '#21d66a',
          cancelButtonColor: '#166cdd',
          confirmButtonText: 'Start live tekensessie',
          cancelButtonText: 'Sluiten'
        }).then((result) => {
          if (result.value) {
            window.open(data.f2f_url, '_blank')
          }
        })
      }).fail(() => {
        errorModal('Er ging iets fout bij het aanmaken van de Face 2 Face URL. Gelieve nogmaals te proberen.')
      })
    }
  }
}
</script>
