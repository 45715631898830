<template>
  <BaseModal ref="infoSheetsModal" title="Infofiches">
    <FormulateForm
      #default="{ isLoading }"
      name="infoSheets"
      @submit="bulkUpdateInfoSheets"
    >
      <div
        v-for="sheet in infoSheets"
        :key="sheet.id"
        class="tw-flex tw-align-center tw-mb-1"
      >
        <input
          type="checkbox"
          :value="sheet.id"
          v-model="checkedSheets"
          class="!tw-mr-2 !tw-my-0 tw-self-center"
        />
        {{ sheet.name }}
      </div>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Infofiches updaten
      </FormulateInput>
      <FormulateErrors />
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { getJobStatus } from '@/services/apiService'
import { getPropertyInfoSheets, getProjectInfoSheets, bulkUpdatePropertyInfoSheets, bulkUpdateProjectInfoSheets } from '@/services/files'
import { poll } from '@/utils/helpers'

export default {
  props: {
    property: {
      type: Object
    },
    project: {
      type: Object
    }
  },
  data () {
    return {
      infoSheets: [],
      checkedSheets: []
    }
  },
  methods: {
    async show () {
      try {
        const response = this.property
          ? await getPropertyInfoSheets(this.property.id)
          : await getProjectInfoSheets(this.project.id)
        this.infoSheets = response?.data?.results
        this.checkedSheets = this.infoSheets?.filter(sheet => sheet.has_file).map(sheet => sheet.id)
        this.$refs.infoSheetsModal.show()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'infoSheets')
      }
    },

    async bulkUpdateInfoSheets () {
      try {
        const response = this.property
          ? await bulkUpdatePropertyInfoSheets(this.property.id, { info_sheet_ids: this.checkedSheets })
          : await bulkUpdateProjectInfoSheets(this.project.id, { info_sheet_ids: this.checkedSheets })
        await poll(response?.data?.job_id, getJobStatus, 1000)
        this.$emit('info-sheets-updated')
        this.$refs.infoSheetsModal.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'infoSheets')
      }
    }
  }
}
</script>
