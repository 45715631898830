<template>
  <div>
    <!-- For entity file uploads, a maximum of 10 files can be uploaded parallely, using a single bulk request -->
    <FileDropzone
      :max-files="10"
      :url="fileUploadUrl"
      :upload-multiple="true"
      :is-entity-file-upload="true"
      @file-uploaded="fileUploaded"
      @all-uploaded="allUploaded"
    >
      <template #droparea="{ loading }">
        <slot name="droparea" :loading="loading" />
      </template>
    </FileDropzone>
    <BaseModal
      ref="moreInfo"
      title="Bestanden uploaden"
      content-padding="tw-px-4 tw-py-2 md:tw-px-8 tw-py-6"
      @hide="uploadedFiles = []"
    >
      <FormulateForm
        #default="{ isLoading }"
        debounce
        name="moreInformation"
        @submit="handleFileUpload"
      >
        <div
          v-if="uploadedFiles.length > 1"
          class="tw-pb-4 tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-border-b"
        >
          <div class="tw-w-1/4" />
          <FormulateInput
            v-model="allPublic"
            debounce
            ignored
            type="toggle"
            name="public"
            label="Publiek"
            :element-class="['tw-mr-auto']"
            :wrapper-class="['tw-flex-col']"
            outer-class="tw-my-0 tw-w-14"
            @input="allModify('public', $event)"
          />
          <FormulateInput
            v-model="selectedFileType"
            debounce
            ignored
            type="select"
            name="file_type"
            label="Bestandstype"
            placeholder="Selecteer een bestandstype"
            :options="fileTypeOptions"
            :input-class="['tw-h-8 tw-text-sm']"
            outer-class="tw-my-0"
            @input="allModify('file_type', $event)"
          />
        </div>
        <FormulateInput
          type="group"
          name="files"
          debounce
          :value="uploadedFiles"
          :repeatable="true"
          validation="allFileTypesRequired"
          :validation-rules="{ allFileTypesRequired }"
          :validation-messages="{
            allFileTypesRequired: 'Alle bestandstypen zijn vereist'
          }"
          :error-class="['tw-text-right']"
          outer-class="tw-mb-1.5"
        >
          <template #default="{ index }">
            <div
              :class="[
                'tw-flex sm:tw-flex-row tw-gap-2 tw-justify-between',
                { 'tw-py-1.5 tw-items-center': uploadedFiles.length > 1 }
              ]"
            >
              <div class="tw-w-1/4">
                <label v-if="uploadedFiles.length === 1" class="formulate-label">
                  Naam
                </label>
                <span class="tw-block tw-break-all tw-leading-tight">
                  {{ uploadedFiles[index] && uploadedFiles[index].filename }}
                </span>
              </div>
              <FormulateInput
                type="toggle"
                name="public"
                :label="uploadedFiles.length === 1 ? 'Publiek' : ''"
                :element-class="['tw-mr-auto']"
                :wrapper-class="['tw-flex-col']"
                outer-class="tw-my-0 tw-w-14"
              />
              <FormulateInput
                type="select"
                name="file_type"
                placeholder="Selecteer een bestandstype"
                :label="uploadedFiles.length === 1 ? 'Bestandstype' : ''"
                :options="fileTypeOptions"
                :input-class="[
                  'tw-h-8 tw-text-sm',
                  { 'tw-mt-0': uploadedFiles.length > 1 }
                ]"
                outer-class="tw-my-0"
              />
            </div>
          </template>
          <template #addmore />
          <template #remove />
        </FormulateInput>
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :outer-class="['tw-float-right']"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import FileDropzone from '@/components/iam/FileDropzone'
import { successModal } from '@/modalMessages'
import { createProjectFilesBulk } from '@/services/projects'
import { createPropertyFilesBulk } from '@/services/properties'

export default {
  name: 'EntityFileUpload',
  components: { FileDropzone },
  props: {
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    },
    folder: {
      type: Object,
      default () {
        return {}
      }
    },
    fileTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uploadedFiles: [],
      selectedFileType: '',
      allPublic: false
    }
  },
  computed: {
    fileUploadUrl () {
      const url = this.property
        ? `/api/v3/property/${this.property.id}/files/upload`
        : `/api/v3/project/${this.project.id}/files/upload`
      return url
    },
    entityId () {
      return this.property ? this.property.id : this.project.id
    },
    createEntityFilesBulk () {
      return this.property ? createPropertyFilesBulk : createProjectFilesBulk
    },
    fileTypeOptions () {
      return this.fileTypes.map(({ name_nl, id }) => {
        return { label: name_nl, value: id }
      })
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    showMoreInfoModal () {
      this.$refs.moreInfo.show()
    },
    hideMoreInfoModal () {
      this.$refs.moreInfo.hide()
    },
    fileUploaded (response) {
      this.uploadedFiles.push(response)
    },
    allUploaded () {
      this.showMoreInfoModal()
    },
    allModify (key, value) {
      this.uploadedFiles.forEach(file => {
        this.$set(file, key, value)
      })
    },
    allFileTypesRequired ({ value }) {
      if (Array.isArray(value)) {
        return value.some(file => !!file.file_type)
      }
      return false
    },
    async handleFileUpload (data) {
      const files = data.files.map(file => {
        if (this.folder?.id !== 'unset') file.folder_id = this.folder?.id
        return file
      })
      const response = await this.createEntityFilesBulk(this.entityId, { files })
      this.refreshPropertyFiles(response.data)
      this.hideMoreInfoModal()
      return response
    },
    refreshPropertyFiles (files) {
      const message = files.length > 1
        ? 'Bestanden zijn succesvol aangemaakt'
        : `Bestand '${files[0]?.filename} is toegevoegd`
      successModal(message)
      this.$emit('fetchFiles')
    }
  }
}
</script>
