<template>
  <div>
    <!-- v-on="$listeners" lets the grandparent component listen to all the events of the grandchild component-->
    <!-- v-bind="$attrs" lets all the attributes applied to the Tree to be applied to TreeNode-->
    <!-- With both these attributes, this (Tree) component acts like a transparent wrapper -->
    <TreeNode
      v-for="(node, index) in nodes"
      :key="index"
      :node="node"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import TreeNode from '@/components/iam/TreeNode'

export default {
  name: 'Tree',
  components: {
    TreeNode
  },
  inheritAttrs: false,
  props: {
    nodes: {
      type: Array,
      default: () => []
    }
  }
}
</script>
