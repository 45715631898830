<template>
  <!--
  If you want to allow a drop, you must prevent the default handling by cancelling both the dragenter and dragover events.
  You can do this either by returning false from attribute-defined event listeners, or by calling the event's preventDefault() method.
  The latter may be more feasible in a function defined in a separate script.

  event.preventDefault() Prevents the browsers default behaviour (such as opening a link), but does not stop the event from bubbling up the DOM.
  -->
  <div
    class="iam-tree-node"
    @dragover.prevent
    @drop.prevent.self="onDrop"
    @dragenter.prevent.self="onDragEnter"
    @dragleave.prevent.self="onDragLeave"
  >
  <!-- tw-pointer-events-none is used to disable pointer events so that the event does not get triggered again and again -->
    <div :class="{ 'tw-pl-2 tw-border-l-4 tw-border-primary tw-pointer-events-none': showActive }">
      <span
        v-if="node.children && node.children.length"
        :class="['iam-tree-node-label', selectedClass]"
        @click="toggle(node)"
      >
        <i :class="[selectedClass, open ? 'fas fa-folder-open' : 'far fa-folders']" /> {{ node.name }}
      </span>
      <template v-else>
        <span :class="['iam-tree-node-label', selectedClass]" @click="selected(node)">
          <i :class="['far fa-folder', selectedClass]" /> {{ node.name }}
        </span>
        <slot />
      </template>
      <button
        v-if="node.createChildren"
        type="button"
        title="Nieuw"
        @click="createChild"
        class="tw-float-right"
      >
        <i class="far fa-plus-square hover:tw-font-bold"/>
      </button>
    </div>
    <div v-show="open" class="iam-tree-node">
      <div v-for="child in node.children" :key="child[identifier]">
        <TreeNode
          :node="child"
          :selected-node="selectedNode"
          :identifier="identifier"
          @selected="selected"
          @openParent="openParent"
        >
          <button
            v-if="node.removeChildren"
            type="button"
            title="Verwijderen"
            @click="$emit('remove', child)"
            class="tw-ml-2 tw-float-right"
          >
            <i class="far fa-trash hover:tw-font-bold" />
          </button>
          <button
            v-if="node.editChildren"
            type="button"
            title="Bewerken"
            @click="$emit('edit', child)"
            class="tw-float-right"
          >
            <i class="far fa-edit hover:tw-font-bold" />
          </button>
        </TreeNode>
      </div>
    </div>
  </div>
</template>

<script>
import TreeNode from '@/components/iam/TreeNode'
import EventBus from '@/components/iam/bus'

export default {
  name: 'TreeNode',
  components: {
    TreeNode
  },
  props: {
    node: {
      type: Object,
      default () {
        return {}
      }
    },
    selectedNode: {
      type: Object,
      default () {
        return {}
      }
    },
    identifier: {
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      open: false,
      showActive: false
    }
  },
  computed: {
    selectedClass () {
      return this.selectedNode[this.identifier] === this.node[this.identifier] ? 'tw-font-bold' : ''
    }
  },
  watch: {
    node () {
      this.init()
    },
    selectedNode () {
      if (this.node.id !== this.selectedNode.id) return
      this.openParent()
    }
  },
  methods: {
    init () {
      this.keepOpen = this.node.children?.length && this.node.keepOpen
      if (this.keepOpen) this.open = true
    },
    toggle (node) {
      if (!this.keepOpen) this.open = !this.open
      this.$emit('selected', node)
    },
    selected (node) {
      this.$emit('selected', node)
    },
    createChild () {
      this.open = true
      this.$emit('new')
    },
    onDrop (event) {
      if (this.node.allowDrop) {
        this.showActive = false
        const itemId = event.dataTransfer.getData('itemId')
        EventBus.$emit('item-dropped-on-node', itemId, this.node.id)
      }
    },
    onDragEnter () {
      if (this.node.allowDrop) this.showActive = true
    },
    onDragLeave () {
      if (this.node.allowDrop) this.showActive = false
    },
    openParent () {
      this.open = true
      this.$emit('openParent')
    }
  }
}
</script>
