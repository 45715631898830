<template>
  <div class="contact-box center-version" style="margin-bottom: 4px;">
    <a class="btn-select-contact" style="padding: 2px;" @click="select" v-if="value">
      <h4 class="m-b-xs">
        <strong>{{ value.display_name }}</strong>
      </h4>
      <p>{{ value.street }} {{ value.number }} {{ value.box}}
        <span v-if="value.city"><br>{{ value.city.zip_code }} {{ value.city.name }}</span>
      </p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ContactButton',
  props: ['value'],
  methods: {
    select: function () {
      this.$emit('select', this.value)
    }
  }
}
</script>

<style scoped>

</style>
