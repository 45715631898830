<template>
  <div class="tw-mb-4">
    <!-- tw-align-start is used to prevent the flex items from stretching their height -->
    <div class="tw-py-2 tw-px-4 tw-bg-gray-100 tw-flex tw-justify-between tw-align-center tw-border tw-border-b-0 tw-flex-wrap tw-items-start">
      <slot name="title">
        <span class="tw-inline-flex tw-items-center tw-font-semibold tw-my-1">{{ title }}</span>
      </slot>
      <div class="tw-inline-flex tw-items-center">
        <a @click="toggleCollapse" class="collapse-link" v-if="isCollapsible">
          <i :class="[collapseButtonClass]" class="fa"></i>
        </a>
        <div class="tw-flex tw-gap-x-2 tw-flex-wrap tw-items-start">
          <slot name="toolbar"></slot>
        </div>
      </div>
    </div>
    <div class="ibox-content" :class="{'show': visible, 'hide': !visible}">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: 'PanelBox',
  props: {
    title: {
      type: String,
      required: false
    },
    isCollapsible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {
    collapseButtonClass () {
      if (this.visible) {
        return 'fa-chevron-up'
      } else {
        return 'fa-chevron-down'
      }
    }
  },
  methods: {
    toggleCollapse () {
      this.visible = !this.visible
    }
  }
}
</script>

<style scoped>
  .panel-title-bg {
    background-color: #f5f5f5;
  }
  .show {
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }
  .ibox-content {
    transition: visibility .3s ease;
  }
</style>
